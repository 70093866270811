import LimitConfigurationComponent from "../../container/admin/LimitConfiguration/LimitConfigurationComponent";
import ScorecardsOrderingComponent from "../../container/admin/ScorecardsOrdering/ScorecardsOrderingComponent";
import UserManagementComponent from "../../container/admin/Usermanagement/UserManagementComponent";
import ScoreCardsComponent from "../../container/lnga/ScoreCards/ScoreCardsComponent";
import MapComponent from "../../container/Map/MapComponent";
import MftComponent from "../../container/Mft/MftComponent";
import ValueChainComponent from "../../container/ValueChain/ValueChainComponent";
import ReleaseContentComponent from "../../container/admin/ReleaseContent/ReleaseContentComponent";
import { URL_SIDE_BAR } from "../map.constants";
import SustainabilityComponent from "../../container/Sustainability/SustainabilityComponent";
import ScorecardManagement from "../../container/admin/ScorecardManagement/ScorecardManagement";
import RiskProfileComponent from "../../container/RiskManagement/RiskProfile/RiskProfileComponent";
import AssuranceComponent from "../../container/RiskManagement/MyAssurance/AssuranceComponent";

export const PEGT_ADMIN_ROUTE = [
  {
    path: URL_SIDE_BAR.USERMANAGEMENT,
    component: UserManagementComponent,
    exact: true,
    icon: "/img/icon/UserManagement.svg",
    alt: "usermanagement",
    text: "USER MANAGEMENT",
    isAdmin: true,
  },
  {
    path: URL_SIDE_BAR.LIMITCONFIGURATION,
    component: LimitConfigurationComponent,
    exact: true,
    icon: "/img/icon/Configuration.svg",
    alt: "limitconfig",
    text: "LIMIT CONFIGURATION",
    isAdmin: true,
  },
  {
    path: URL_SIDE_BAR.SCORECARDORDERING,
    component: ScorecardsOrderingComponent,
    exact: true,
    icon: "/img/icon/ScorecardOrdering.svg",
    alt: "scorecardsordering",
    text: "SCORECARD ORDERING",
    isAdmin: true,
  },
  {
    path: URL_SIDE_BAR.SCORECARD_MANAGEMENT,
    component: ScorecardManagement,
    exact: true,
    icon: "/img/icon/ScoreManagement.svg",
    alt: "scoremanagement",
    text: "SCORECARD MANAGEMENT",
    isAdmin: true,
  },
  {
    path: URL_SIDE_BAR.MAP,
    component: MapComponent,
    exact: true,
    icon: "/img/icon/Icon Maps.svg",
    alt: "Toggle map",
    text: "MAP",
    isAdmin: false,
  },
  {
    path: URL_SIDE_BAR.VALUE_CHAIN,
    component: ValueChainComponent,
    exact: true,
    icon: "/img/icon/Icon Value Chain.svg",
    alt: "Value Chain",
    text: "VALUE CHAIN",
    isAdmin: false,
  },
  {
    path: URL_SIDE_BAR.PEGT_SCORECARDS,
    component: ScoreCardsComponent,
    exact: true,
    icon: "/img/icon/Icon Scorecard.svg",
    alt: "Scorecards",
    text: "SCORECARDS",
    isAdmin: false,
  },
  {
    path: URL_SIDE_BAR.MFT,
    component: MftComponent,
    exact: true,
    icon: "/img/icon/Mft.svg",
    alt: "MFT",
    text: "MFT",
    isAdmin: false,
  },
  {
    path: URL_SIDE_BAR.RELEASE_CONTENT,
    component: ReleaseContentComponent,
    exact: true,
    icon: "/img/icon/release-content.svg",
    alt: "releasecontent",
    text: "RELEASE CONTENT",
    isAdmin: true,
    isSuperAdmin: true,
  },
  {
    path: URL_SIDE_BAR.SUSTAINABILITY,
    component: SustainabilityComponent,
    exact: true,
    icon: "/img/icon/Icon Maps.svg",
    alt: "Toggle Sustainability",
    text: "SUSTAINABILITY",
    isAdmin: false,
  },
  {
    path: URL_SIDE_BAR.ASSURANCE,
    component: AssuranceComponent,
    exact: true,
    icon: "/img/icon/admin-module.svg",
    alt: "Assurance",
    text: "Assurance",
    isAdmin: false,
  },
  {
    path: URL_SIDE_BAR.RISK_PROFILE,
    component: RiskProfileComponent,
    exact: true,
    iconActive: "/img/icon/riskProfile-active.svg",
    icon: "/img/icon/riskProfile.svg",
    alt: "Risk Profile",
    text: "Risk Profile",
    isAdmin: false,
  }
];
