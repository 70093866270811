import React, { useState } from 'react'
import { MONTH_SHORT_TEXT } from '../../constants/score-card.constants';
import s from "../../components/DateDropdown/DateDropdown.module.css";
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';
import moment from 'moment';
import { DatePicker, Space } from 'antd';

const { MonthPicker } = DatePicker;

// interface IDate { month: number, year: number }

// interface IProp {
//     currentDate: IDate;
//     onDateChange: (date: IDate) => void
// }

// const DateRangePicker = (prop: IProp) => {
const DateRangePicker = () => {
    const [showPeriodFilter, setShowPeriodFilter] = useState(false);
    // const { onDateChange, currentDate } = prop
    
    function handleDateSelect($event) {
        // onDateChange({ month: $event.month() + 1, year: $event.year() })
        setShowPeriodFilter(false);
    }

    const makeText = (m) => {
        if (m && m.year && m.month)
            return MONTH_SHORT_TEXT[m.month - 1] + " " + m.year;
        return "?";
    };

    // function increaseDate() {
    //     let newMonth = currentDate.month + 1;
    //     let newYear = currentDate.year
    //     if (newMonth > 12) {
    //         newMonth = 1;
    //         newYear++
    //     }
    //     onDateChange({ month: newMonth, year: newYear })
    // }

    // function decreaseDate() {
    //     let newMonth = currentDate.month - 1;
    //     let newYear = currentDate.year
    //     if (newMonth < 1) {
    //         newMonth = 12;
    //         newYear--
    //     }
    //     onDateChange({ month: newMonth, year: newYear })
    // }

    return (
        <div className={s.filterBlocks}>
            <div className={s.filterBlock}>
                <span>FROM</span>
                <div
                    className={`${s.filterBlockDropdown}`}
                    onBlur={() => setShowPeriodFilter(false)}
                >
                    <div className={s.buttonContainer}>
                        {/* <CaretLeftOutlined style={checkDisabledPreviousButton() ? { color: 'gray', pointerEvents: 'none' } : {}} onClick={decreaseDate} /> */}
                        <button
                            type='button'
                            onClick={() =>
                                setShowPeriodFilter(!showPeriodFilter)
                            }
                        >
                            {/* {makeText(currentDate)} */}
                        </button>
                        {/* <CaretRightOutlined style={checkDisabledNextButton() ? { color: 'gray', pointerEvents: 'none' } : {}} onClick={increaseDate} /> */}
                    </div>
                    <div className='scorecards-month-picker'>
                        <Space direction='vertical'>
                            <MonthPicker
                                // disabledDate={disabledDate}
                                // value={moment(
                                //     `${currentDate.year}-${currentDate.month}`,
                                //     'YYYY-MM'
                                // )}
                                onChange={handleDateSelect}
                                open={showPeriodFilter}
                            />
                        </Space>
                    </div>
                </div>
            </div>
            <div className={s.filterBlock}>
                <span>TO</span>
                <div
                    className={`${s.filterBlockDropdown}`}
                    onBlur={() => setShowPeriodFilter(false)}
                >
                    <div className={s.buttonContainer}>
                        {/* <CaretLeftOutlined style={checkDisabledPreviousButton() ? { color: 'gray', pointerEvents: 'none' } : {}} onClick={decreaseDate} /> */}
                        <button
                            type='button'
                            onClick={() =>
                                setShowPeriodFilter(!showPeriodFilter)
                            }
                        >
                            {/* {makeText(currentDate)} */}
                        </button>
                        {/* <CaretRightOutlined style={checkDisabledNextButton() ? { color: 'gray', pointerEvents: 'none' } : {}} onClick={increaseDate} /> */}
                    </div>
                    <div className='scorecards-month-picker'>
                        <Space direction='vertical'>
                            <MonthPicker
                                // disabledDate={disabledDate}
                                // value={moment(
                                //     `${currentDate.year}-${currentDate.month}`,
                                //     'YYYY-MM'
                                // )}
                                onChange={handleDateSelect}
                                open={showPeriodFilter}
                            />
                        </Space>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DateRangePicker