import s from "./AssuranceComponent.module.css";
import { useEffect, useState, useMemo } from "react";
import { get, set } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { Breadcrumb } from "antd";
import DateRangePicker from "../../../components/DateRangePicker/DateRangePicker";

const AssuranceComponent = () => {
	const [isLoaded, setIsLoaded] = useState(false);

	const handleLoad = () => {
    setIsLoaded(true);
  };

	const breadCrumb = () => {
		return (
			<Breadcrumb
				className={s.mapBreadcrumb}
				separator={
        <img
          className={s.breadcrumbSeparator}
          alt='separator-icon'
          src='/img/icon/arrow-right.svg'
        />
      }
    >
      <div className={s.mapBreadcrumbItem}>
				<span>Gas Assurance Performance</span>
			</div>
    </Breadcrumb>
		)
    // return <Breadcrumb
    //   className={s.mapBreadcrumb}
    //   separator={
    //     <img
    //       className={s.breadcrumbSeparator}
    //       alt='separator-icon'
    //       src='/img/icon/arrow-right.svg'
    //     />
    //   }
    // >
    //   {BREADCRUMB[selectedPersona]?.map((OPU, index) => {
    //     return (
    //       <Breadcrumb.Item
    //         className={`${s.mapBreadcrumbItem} ${index === BREADCRUMB[selectedPersona].length - 1 ? s.currentLocationBcItem : ''
    //           }`}
    //         key={OPU}
    //         onClick={() => handleChangeOPU(OPU === "Gas Business Scorecard" ? OPU_UPCS.GNE : OPU)}
    //       >
    //         {OPU}
    //       </Breadcrumb.Item>
    //     );
    //   })}
    // </Breadcrumb>
  }

  // Dummy OPU tab array
  const opuTabArr = [
    {
      label: "Gas Business",
      value: 1
    },
    {
      label: "LNGA",
      value: 2
    },
    {
      label: "G&P",
      value: 3
    },
    {
      label: "LMT",
      value: 4
    },
    {
      label: "GCO",
      value: 5
    }
  ];
  

	return (
		<div onLoad={handleLoad} className={`fullscreen-pt-0 ${s.assuranceRoot}`}>
			<div className={`page-container bg-risk-management ${s.assuranceContainer}`}>
				<div className={s.assurancePanel}>
					<div className={s.assurancePanelHeader}>
						{breadCrumb()}
						{/* <DateRangePicker currentDate={currentDate} onDateChange={onDateChange} /> */}
						<DateRangePicker />
					</div>
					<div className={s.assurancePanelBody}>
            <div className={s.opuTab}>
              <ul>
                {opuTabArr.map((v, i) => {
                  return (
                    <li
                      key={i}
                      // onClick={() => {
                      //   handleChangeTab(v.value);
                      // }}
                      // onKeyDown={(e) => {
                      //   if (e.key === 'Enter') {
                      //     handleChangeTab(v.value);
                      //   }
                      // }}
                      tabIndex={0}
                      // className={
                      //   (sectionIdActive === v.value ? s.active : '') +
                      //   (levelScoreCard === 2 &&
                      //     (v.value === TAB_SCORE_CARD.HSSE_PERFORMANCE || v.value === TAB_SCORE_CARD.STRATEGIC_INITIATIVES
                      //       || v.value === TAB_SCORE_CARD.FINANCIAL_EXCELLENCE || v.value === TAB_SCORE_CARD.PRODUCTION_GENERATION
                      //       || v.value === TAB_SCORE_CARD.PLANT_PERFORMANCE)
                      //     ? s.disableTabLi
                      //     : '')
                      // }
                    >
                      {v.label}
                    </li>
                  );
                })}
              </ul>
            </div>
					</div>
				</div>
			</div>
		</div>
  );
};

export default AssuranceComponent;
